import styled from "styled-components";

const AddDataWrapper = styled.div`
  min-height: 100vh;

  #maincontent {
    & .row {
      margin-right: 0px;
    }
    @media (min-width: 992px) {
      height: 100%;
      flex-grow: 1;
    }
    & .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
      }
      & .left-container {
        padding: 10px;
        @media (min-width: 550px) {
          padding: 15px;
        }
        @media (min-width: 991px) {
          padding: 19px 10px 19px 21px;
        }
        & main {
          @media (min-width: 360px) {
            display: flex;
            flex-direction: column;
            & .titleDiv {
              margin-bottom: 20px;
              padding-bottom: 20px;
              border-bottom: 0.6000000238418579px solid #bcc3ca;
            }
          }
          & .titleDiv {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 0.6000000238418579px solid #bcc3ca;
            font-size: 28px !important;
          }
        }
      }
    }
  }
`;
export default AddDataWrapper;
