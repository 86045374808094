import styled from "styled-components";

export const UploadProjects = styled.div`
  & .button-group {
    margin-top: 20px;
  }
  & .btn {
    margin: 0px 20px;
  }
`;
export default UploadProjects;
