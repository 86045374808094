/* eslint-disable no-console */
import axios from "axios";
import CONFIG from "../../config";
import GetReportById from "./GetReportById";

const CreateReportApi = async (reportForm: any) => {
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = "1";
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
      "Content-Type": "application/json",
    },
  };

  function addDays(date: any, days: any) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  function removeDays(date: any, days: any) {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  const today = new Date();

  const oneJan = new Date(today.getFullYear(), 0, 1);
  const numberOfDays = Math.floor(
    (today.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000)
  );
  const Week = Math.ceil((today.getDay() + numberOfDays) / 7);

  const startDate = new Date(reportForm.start_date);
  console.log(startDate);
  const lastDate = new Date(reportForm.end_date);
  console.log(lastDate);

  let endDate = startDate;

  let week = 0;
  while (endDate <= lastDate) {
    if (endDate.getDay() === 5) {
      week += 1;

      if (
        today.toISOString().substring(0, 10) <=
        endDate.toISOString().substring(0, 10)
      ) {
        break;
      }
    }
    endDate = addDays(endDate, 1);
  }

  let firstDate = removeDays(endDate, 4);
  if (
    firstDate.toISOString().substring(0, 10) <=
    startDate.toISOString().substring(0, 10)
  ) {
    firstDate = startDate;
  }

  function setArrows(reportField: any, prevField: any) {
    switch (reportField) {
      case "goodchange":
        switch (prevField) {
          case "goodchange":
            return "nochangegood";
          case "badchange":
            return "goodchange";
          case "nochangegood":
            return "nochangegood";
          case "nochangebad":
            return "goodchange";
          default:
            break;
        }
        break;
      case "badchange":
        switch (prevField) {
          case "goodchange":
            return "badchange";
          case "badchange":
            return "nochangebad";
          case "nochangegood":
            return "badchange";
          case "nochangebad":
            return "nochangebad";
          default:
            break;
        }
        break;
      default:
        break;
    }
    return reportField;
  }

  // eslint-disable-next-line use-isnan
  if (!Number.isNaN(reportForm.reportId)) {
    const previousReport = await GetReportById(reportForm.reportId);
    const prev = previousReport.data.Report;

    reportForm.scope = setArrows(reportForm.scope, prev.scope);
    reportForm.quality = setArrows(reportForm.quality, prev.quality);
    reportForm.budget = setArrows(reportForm.budget, prev.budget);
    reportForm.schedule = setArrows(reportForm.schedule, prev.schedule);
    reportForm.overall_health = setArrows(
      reportForm.overall_health,
      prev.overall_health
    );
  }

  const formData = {
    budget: reportForm.budget,
    budget_comment: reportForm.budget_comment,
    schedule: reportForm.schedule,
    schedule_comment: reportForm.schedule_comment,
    overall_health: reportForm.overall_health,
    overall_health_comment: reportForm.overall_health_comment,
    overall_health_percentage: reportForm.overall_health_percentage,
    scope: reportForm.scope,
    scope_comment: reportForm.scope_comment,
    pi_id: reportForm.pi_id,
    project_id: reportForm.projectId,
    start_date: firstDate,
    end_date: endDate,
    quality: reportForm.quality,
    quality_comment: reportForm.quality_comment,
    week: Week,
  };

  await axios
    .post(
      `${
        CONFIG[
          process.env.REACT_APP_MY_ENV === undefined
            ? "dev"
            : process.env.REACT_APP_MY_ENV
        ].apiEndPoints.phrt
      }/v1/reports`,
      JSON.stringify(formData),
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      console.log(error.response.status);
      console.log(error.response.data.error.details[0].message);
      console.log(error.message);
      throw error;
    });
};

export default CreateReportApi;
