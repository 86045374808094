import React, { useEffect, useState } from "react";
import { AiOutlineLineChart } from "react-icons/ai";
import { TfiBarChartAlt } from "react-icons/tfi";
import { FaMountain } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getQaAutomationReport } from "services/api/GetQaAutomationReportsApi";
import axios from "axios";
import { format } from "date-fns";
import FilterStyle from "./styled";

const Filter: any = (props: any) => {
  const { filter, updateFilter } = props;
  const today = new Date();
  const defstartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const defendDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 28
  );

  const defaultendDate = defstartDate.toISOString().split("T")[0];
  const defaultstartDate = defendDate.toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(defendDate);
  const [endDate, setEndDate] = useState(defstartDate);
  const handleDateChange = (dates: [any, any]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start) {
      const formattedStartDate = format(start, "yyyy-MM-dd");
      updateFilter("startDateRange", formattedStartDate);
    }
    if (end) {
      const formattedEndDate = format(end, "yyyy-MM-dd");
      updateFilter("endDateRange", formattedEndDate);
    }
  };

  // const [startDateValue, setStartDateValue] = useState();
  // const [endDateValue, setEndDateValue] = useState();
  // useEffect(() => {
  //   getQaAutomationReport(props)
  //     .then((response) => {
  //       console.log("response", response);
  //       if (response.status === 200 && response.data) {
  //         const data = response?.data?.qaReports.map((item: any) => {
  //           console.log("data", data);
  //           return {
  //             id: item.id,
  //             startDate: item.startDate,
  //             endDate: item.endDate,
  //           };
  //         });

  //         setStartDateValue(startDate);
  //         setEndDateValue(endDate);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Error:", error);
  //     });
  // }, [props]);
  return (
    <FilterStyle>
      <div className="filter-items row">
        <div className="filter-icon-item col-lg-3 col-md-6 col-sm-12">
          <button
            type="button"
            className="graph-btn"
            onClick={() => {
              updateFilter("graphType", "Line");
            }}
          >
            <AiOutlineLineChart />
          </button>
          {/* <button
            type="button"
            className="graph-btn"
            onClick={() => {
              updateFilter("graphType", "Step");
            }}
          >
            <BsBarChartSteps />
          </button> */}
          <button
            type="button"
            className="graph-btn"
            onClick={() => {
              updateFilter("graphType", "Bar");
            }}
          >
            <TfiBarChartAlt />
          </button>
          {/* <button
            type="button"
            className="graph-btn"
            onClick={() => {
              updateFilter("graphType", "Candle");
            }}
          >
            <BiCandles />
          </button> */}
          <button
            type="button"
            className="graph-btn"
            onClick={() => {
              updateFilter("graphType", "Mountain");
            }}
          >
            <FaMountain />
          </button>
        </div>
        <div className="filter-data-item col-9 col-md-6 col-sm-12 mt-2">
          <div className="mr-4">
            <h3 className="reports">Reports</h3>
            <select
              id="dropdown"
              onChange={(e) => updateFilter("reportsType", e.target.value)}
            >
              <option value="Automation">Automation</option>
              <option value="Sprint">Sprint Defects</option>
            </select>
          </div>

          <div className="mr-4">
            <h3 className="projects">Projects</h3>
            <select
              id="dropdown"
              onChange={(e) => updateFilter("projectName", e.target.value)}
            >
              <option value="ADH">ADH</option>
              <option value="Atlas React">Atlas React</option>
              <option value="CE">CE</option>
              <option value="CP">CP</option>
              <option value="eBooks">eBooks</option>
              <option value="eLearning">eLearning</option>
              <option value="Intelligo">Intelligo</option>
              <option value="Intelligo premier">Intelligo premier</option>
              <option value="Intelligo Store">Intelligo Store</option>
              <option value="QCT">QCT</option>
              <option value="ShopCPR">ShopCPR</option>
            </select>
          </div>
          <div className="date-picker-wrapper">
            {" "}
            <h3 className="projects">Date Picker</h3>
            <DatePicker
              dateFormat="dd-MM-yyyy"
              className="date-picker"
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              placeholderText="Select Date Range"
              required
            />
          </div>
        </div>
      </div>
    </FilterStyle>
  );
};
export default Filter;
