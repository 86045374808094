import styled from "styled-components";

const DashboardQAstyle = styled.div`
  min-height: 100vh;
  body {
    box-sizing: border box;
  }
  .date-picker {
    padding: 3px;
    background: none;
    border: none;
  }
  .date-picker:hover {
    cursor: pointer;
  }
  main {
    height: 100%;
    flex-grow: 1;
    .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (min-width: 320px) and (max-width: 576px) {
        margin-top: -9px;
      }
      @media (min-width: 992px) {
        flex-direction: row;
        margin-left: 0px;
      }
      .left-container {
        padding-inline: 20px;
        @media (min-width: 992px) {
          padding: 19px 10px 19px 21px;
        }
        .T-Title {
          padding-top: 32px;
          padding-bottom: 20px;
          .T-Titles {
            font: normal normal medium 18px/27px Montserrat;
            font-weight: 600;
          }
          .S-status {
            font-size: 12px;
            text-align: center;
          }

          .Status-Updated {
            margin-left: 28.67px;
          }
          @media (min-width: 320px) {
            flex-direction: column;
            justify-content: none;
            padding-top: 32px;

            .S-Stauses {
              justify-content: flex-start !important;
              font-size: 12px;
            }
          }
          @media (min-width: 992px) {
            flex-direction: row;
            justify-content: space-between;
            padding-top: 32px;

            .S-Stauses {
              justify-content: none;
              font-size: 12px;
            }
          }
        }
        .scrollable-project-list {
          height: 350px;
          overflow-y: auto;
          overflow-x: auto;
          ::-webkit-scrollbar {
            width: 4px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #888;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }
    }
  }
  & .titleDiv h3 {
    font: normal normal 600 24px/49px Montserrat;
  }
  & .T-Titles {
    font: normal normal medium 18px/27px Montserrat;
    font-weight: 600;
  }
  & .forecast {
    margin-bottom: 20px;
  }
  & .button-project-list {
    min-height: 20px;
    text-align: left;
  }
  & .text-danger {
    text-decoration: underline;
    cursor: pointer;
  }
`;
export default DashboardQAstyle;
