import React from "react";
import BarAutomationGraph from "./BarAutomationGraph.tsx";
import BarSprintGraph from "./BarSprintGraph.tsx";
import { BarGraphWrapper } from "./styled";

const BarGraph: any = (props: any) => {
  const { reportsType, projectName, startDate, endDate, forecast, graphType } =
    props;
  return (
    <BarGraphWrapper className="echart-wrapper pl-lg-0 ">
      <div className="row mt-4 m-sm-0 mt-sm-4 align-items-center">
        <div className="col-sm-5 chart-dropdown">{/* Add Filter here */}</div>
      </div>

      {reportsType === "Sprint" && (
        <BarSprintGraph
          projectName={projectName}
          startDate={startDate}
          endDate={endDate}
          forecast={forecast}
          graphType={graphType}
        />
      )}

      {reportsType === "Automation" && (
        <BarAutomationGraph
          projectName={projectName}
          startDate={startDate}
          endDate={endDate}
          forecast={forecast}
          graphType={graphType}
        />
      )}
    </BarGraphWrapper>
  );
};

export default BarGraph;
