import { BarChart } from "echarts/charts";
import {
  AriaComponent,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DatasetComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { UniversalTransition } from "echarts/features";
import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import React, { useEffect, useRef, useState } from "react";
import getSprintReport from "services/api/GetSprintReportsApi";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { BarGraphWrapper } from "../styled";

interface SprintGraphData {
  xAxis: string[];
  yaxisTotalCount: any[];
  yaxisOpenCount: any[];
  yaxisClosedCount: any[];
  yaxisInProgressCount: any[];
}

interface SprintGraphValue {
  week: string[];
  total: number[];
  open: number[];
  closed: number[];
  inProgress: number[];
}
const BarSprintGraph: any = (props: any) => {
  // Graph Related
  const { projectName, startDate, endDate, forecast, graphType } = props;
  const [graphData, setGraphData] = useState<SprintGraphData>({
    xAxis: ["Week 1", "Week 2", "Week 3", "Week 4"],
    yaxisTotalCount: [50, 60, 40, 70],
    yaxisOpenCount: [20, 30, 10, 40],
    yaxisClosedCount: [30, 20, 30, 0],
    yaxisInProgressCount: [0, 10, 0, 30],
  });
  const [loading, setLoading] = useState(true);

  const getOption = () => {
    return {
      baseOption: {
        tooltip: {
          trigger: "item",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {
          orient: "horizontal",
          x: "center",
          y: "bottom",
          icon: "circle",
          textStyle: {
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: "#222328",
            fontSize: 14,
          },
          itemGap: 20,
          lineHeight: 18,
          itemHeight: 9,
          itemWidth: 9,
          padding: 10,
        },
        grid: {
          top: "5%",
          left: "0%",
          right: "9%",
          bottom: "20%",
          containLabel: true,
          show: true,
          borderColor: "#ccc",
        },
        xAxis: {
          data: graphData.xAxis,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#555555",
            },
          },
        },
        yAxis: {},
        series: [
          {
            id: 1,
            name: "Total",
            type: "bar",
            stack: "one",
            color: "#707070",
            barWidth: 30,
            data: graphData.yaxisTotalCount,
          },
          {
            id: 2,
            name: "Open",
            type: "bar",
            stack: "one",
            color: "#FCC503",
            barWidth: 30,
            data: graphData.yaxisOpenCount,
          },
          {
            id: 3,
            name: "Closed",
            type: "bar",
            stack: "one",
            color: "#01A9A3",
            barWidth: 30,
            data: graphData.yaxisClosedCount,
          },
          {
            id: 4,
            name: "In Progress",
            type: "bar",
            stack: "one",
            color: "#F5A623",
            barWidth: 30,
            data: graphData.yaxisInProgressCount,
          },
        ],
      },
    };
  };
  const getCurrentEchartInstance = (chart: any) => {
    return chart.getEchartsInstance();
  };
  const chartDom = useRef(null);
  let chartInstance: any = null;
  async function renderChart(chart: any) {
    const renderInstance = getCurrentEchartInstance(chart);
    if (renderInstance) {
      chartInstance = renderInstance;
    } else {
      chartInstance = echarts.init(chart);
    }
    await chartInstance.setOption({
      baseOption: {
        title: {
          show: false,
        },
        yAxis: {},
        series: [
          {
            name: "Total",
            data: graphData.yaxisTotalCount,
          },
          {
            name: "Open",
            data: graphData.yaxisOpenCount,
          },
          {
            name: "Closed",
            data: graphData.yaxisClosedCount,
          },
          {
            name: "In Progress",
            data: graphData.yaxisInProgressCount,
          },
        ],
      },
      // Media Queries
      media: [
        {
          option: {
            legend: {
              emphasis: {
                selectorLabel: {
                  show: false,
                },
              },
            },
            series: [
              {
                name: "Total",
                emphasis: {
                  disabled: false,
                  focus: "series",
                },
                legendHoverLink: true,
              },
              {
                name: "Open",
                emphasis: {
                  disabled: false,
                  focus: "series",
                },
                legendHoverLink: true,
              },
              {
                name: "Closed",
                emphasis: {
                  disabled: false,
                  focus: "series",
                },
                legendHoverLink: false,
              },
              {
                name: "In Progress",
                emphasis: {
                  disabled: false,
                  focus: "series",
                },
                legendHoverLink: false,
              },
            ],
          },
        },
        {
          query: {
            maxWidth: 767,
          },
          option: {
            legend: {
              emphasis: {
                selectorLabel: {
                  show: false,
                },
              },
            },
            series: [
              {
                name: "Total",
                emphasis: {
                  disabled: true,
                  focus: "none",
                },
                legendHoverLink: false,
              },
              {
                name: "Open",
                emphasis: {
                  disabled: true,
                  focus: "none",
                },
                legendHoverLink: false,
              },
              {
                name: "Closed",
                emphasis: {
                  disabled: true,
                  focus: "none",
                },
                legendHoverLink: false,
              },
              {
                name: "In Progress",
                emphasis: {
                  disabled: true,
                  focus: "none",
                },
                legendHoverLink: false,
              },
            ],
          },
        },
      ],
    });
  }

  useEffect(() => {
    if (chartDom.current !== null) {
      const chart: any = chartDom.current;
      const renderInstance = getCurrentEchartInstance(chart);
      if (renderInstance) {
        chartInstance = renderInstance;
      } else {
        chartInstance = echarts.init(chart);
      }
    }
  }, []);

  // Use Effect to monitor and update the graph on change in dropdown
  const GetSprintApi = async () => {
    getSprintReport(projectName, startDate, endDate)
      .then(async (response: any) => {
        const { qaReports } = response.data;
        let xaxis: any[] = [];
        let yaxisTotal: any[] = [];
        let yaxisClosed: any[] = [];
        let yaxisOpen: any[] = [];
        let yaxisInProgress: any[] = [];
        qaReports.forEach((val: SprintGraphValue) => {
          const newDates: any = new Date(`${val.week}`); // Change the date format as per your requirement
          xaxis = [...xaxis, newDates];
          yaxisTotal = [...yaxisTotal, val.total];
          yaxisClosed = [...yaxisClosed, val.closed];
          yaxisOpen = [...yaxisOpen, val.open];
          yaxisInProgress = [...yaxisInProgress, val.inProgress];
        });
        setGraphData({
          ...graphData,
          xAxis: xaxis,
          yaxisTotalCount: yaxisTotal,
          yaxisClosedCount: yaxisClosed,
          yaxisOpenCount: yaxisOpen,
          yaxisInProgressCount: yaxisInProgress,
        });
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occurred",
            message: error.message,
          })
        );
      });
  };
  // Use Effect to monitor and update the graph on change in dropdown
  useEffect(() => {
    // Axios request to hit endpoint
    setLoading(true);
    GetSprintApi();
  }, [startDate, endDate, projectName, graphType]);

  useEffect(() => {
    // Update chart values
    if (
      graphData.yaxisClosedCount.reduce((a, b) => a + b, 0) === 0 &&
      graphData.yaxisInProgressCount.reduce((a, b) => a + b, 0) === 0 &&
      graphData.yaxisOpenCount.reduce((a, b) => a + b, 0) === 0 &&
      graphData.yaxisTotalCount.reduce((a, b) => a + b, 0) === 0
    ) {
      if (chartDom.current !== null) {
        const renderInstance = getCurrentEchartInstance(chartDom.current);
        renderInstance.setOption({
          title: {
            show: true,
            textStyle: {
              color: "grey",
              fontSize: 20,
            },
            text: "No data",
            left: "center",
            top: "center",
          },
        });
        setLoading(false);
      }
    } else {
      if (chartDom.current !== null) {
        const barChart = chartDom.current;
        renderChart(barChart);
      }
      setLoading(false);
    }
    document.querySelector<any>(".credential-bar")?.setAttribute("role", "img");
  }, [graphData]);

  useEffect(() => {
    return () => {
      if (chartDom.current !== null) {
        chartInstance = getCurrentEchartInstance(chartDom.current);
      }
    };
  }, []);
  // Loading elements
  const loadingOption = {
    text: "loading",
    color: "#c23531",
    textColor: "#000",
    maskColor: "rgba(255, 255, 255, 0.8)",
    zlevel: 0,
    fontSize: 12,
    showSpinner: true,
    spinnerRadius: 10,
    lineWidth: 5,
    fontWeight: "normal",
    fontStyle: "normal",
  };
  useEffect(() => {
    // Update chart
    if (chartDom.current !== null) {
      const chart = getCurrentEchartInstance(chartDom.current);
    }
  }, [loading]);

  // Register the required components
  echarts.use([
    CanvasRenderer,
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    BarChart,
    LegendComponent,
    UniversalTransition,
    AriaComponent,
  ]);
  return (
    <BarGraphWrapper className="echart-wrapper">
      {/* BAR CHART */}
      <ReactEChartsCore
        echarts={echarts}
        ref={chartDom}
        option={getOption()}
        loadingOption={loadingOption}
        className="echart-wrapper credential-bar pl-md-2"
        lazyUpdate
      />
    </BarGraphWrapper>
  );
};

export default BarSprintGraph;
