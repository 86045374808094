import axios from "axios";
import CONFIG from "../../config";

const getSprintReport = async (
  projectName: any,
  startDate: any,
  endDate: any
): Promise<any> => {
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = 1;
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(
      `${
        CONFIG[
          process.env.REACT_APP_MY_ENV === undefined
            ? "dev"
            : process.env.REACT_APP_MY_ENV
        ].apiEndPoints.dashboard
      }/v1/jiraAutomationReports?projectName=${projectName}&startDate=${startDate}&endDate=${endDate}`,
      config
    )
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export default getSprintReport;
