import styled from "styled-components";

export const QAReportsWrapper = styled.div`
  min-height: 100vh;

  #maincontent {
    & .row {
      margin-right: 0px;
    }
    @media (min-width: 992px) {
      height: 100%;
      flex-grow: 1;
    }
    & .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
      }
      & .left-container {
        padding: 10px;
        @media (min-width: 550px) {
          padding: 15px;
        }
        @media (min-width: 991px) {
          padding: 19px 10px 19px 21px;
        }
        & main {
          @media (min-width: 360px) {
            display: flex;
            flex-direction: column;
            & .titleDiv {
              margin-bottom: 20px;
              padding-bottom: 20px;
              border-bottom: 0.6000000238418579px solid #bcc3ca;
            }
          }
        }
      }
    }
  }
  .main-title {
    font: normal normal 600 24px/33px Montserrat;
  }

  .l-block-bg {
    background: url("../images/home.jpg") no-repeat;
    background-size: cover;
    background-position-x: 100%;
    max-width: none;
    flex: auto;
  }
  .aha-certification-flex-logo {
    margin-bottom: 32px;
    @media only screen and (min-width: 992px) {
      margin-bottom: 49px;
    }
  }
  .l-block {
    position: relative;
    min-height: 100vh;
    > div {
      min-height: auto;
      @media only screen and (min-width: 992px) {
        min-height: 100vh;
      }
    }
    .l-block-bg {
      height: 508px;
      @media only screen and (min-width: 768px) {
        height: 654px;
      }
      @media only screen and (min-width: 992px) {
        height: auto;
      }
    }
  }
  .l-form {
    width: 92%;
    padding-top: 43px;
    padding-bottom: 36px;
    margin-bottom: 40px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 18px #00000029;
    p {
      font-size: 16px;
      color: var(--bread-crumbs-6d6e71);
    }
    > div {
      padding: 0 18px;
    }
    @media only screen and (min-width: 576px) {
      width: 88%;
      padding-top: 45px;
      padding-bottom: 45px;
      > div {
        padding: 0 32px;
      }
      p {
        font-size: 18px;
      }
    }
    @media only screen and (min-width: 768px) {
      width: 86%;
      padding-top: 77px;
      padding-bottom: 92px;
      margin-bottom: 70px;
      > div {
        padding: 0 40px;
      }
      p {
        font-size: 18px;
      }
    }
    @media only screen and (min-width: 992px) {
      width: 100%;
      padding: 20px 0;
      margin-bottom: 0;
      box-shadow: none;
      > div {
        padding: 16px;
      }
      p {
        font-size: 22px;
      }
    }
  }
  .l-container {
    position: absolute;
    left: 0;
    top: 120px;
    @media only screen and (min-width: 768px) {
      top: 180px;
    }
    @media only screen and (min-width: 992px) {
      position: static;
    }
  }
  .btn {
    width: 100%;
    @media only screen and (min-width: 768px) {
      width: auto;
    }
  }
`;
export const AnotherWraper = styled.div``;
