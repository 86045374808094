import { BarChart } from "echarts/charts";
import {
  AriaComponent,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DatasetComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { UniversalTransition } from "echarts/features";
import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import React, { useEffect, useRef, useState } from "react";
import { getQaAutomationReport } from "services/api/GetQaAutomationReportsApi";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { BarGraphWrapper } from "../styled";

interface AutomationGraphData {
  xAxis: string[];
  totalAutomated: any[];
  totalPending: any[];
  totalCurrentCompleted: any[];
}

interface AutomationGraphValue {
  week: string[];
  totalAutomated: number[];
  totalPending: number[];
  totalCurrentCompleted: number[];
}
const BarAutomationGraph: any = (props: any) => {
  // Graph Related
  const { projectName, startDate, endDate, forecast, graphType } = props;
  const [graphData, setGraphData] = useState<AutomationGraphData>({
    xAxis: ["Week 1", "Week 2", "Week 3", "Week 4"],
    totalAutomated: [50, 60, 40, 70],
    totalPending: [20, 30, 10, 40],
    totalCurrentCompleted: [30, 20, 30, 0],
  });
  const [loading, setLoading] = useState(true);

  const getOption = () => {
    return {
      baseOption: {
        tooltip: {
          trigger: "item",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {
          icon: "circle",
          orient: "horizontal",
          x: "center",
          y: "bottom",
          textStyle: {
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: "#222328",
            fontSize: 14,
          },
          itemGap: 20,
          lineHeight: 18,
          itemHeight: 9,
          itemWidth: 9,
          padding: 10,
        },
        grid: {
          top: "5%",
          left: "0%",
          right: "9%",
          bottom: "20%",
          containLabel: true,
          show: true,
          borderColor: "#ccc",
        },
        xAxis: {
          data: graphData.xAxis,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#555555",
            },
          },
        },
        yAxis: {},
        series: [
          {
            id: 1,
            name: "Total Automated",
            type: "bar",
            stack: "one",
            color: "#707070",
            barWidth: 30,
            data: graphData.totalAutomated,
          },
          {
            id: 2,
            name: "Total Pending",
            type: "bar",
            stack: "one",
            color: "#FCC503",
            barWidth: 30,
            data: graphData.totalPending,
          },
          {
            id: 3,
            name: "Total Current Completed",
            type: "bar",
            stack: "one",
            barWidth: 30,
            color: "#01A9A3",
            data: graphData.totalCurrentCompleted,
          },
        ],
      },
    };
  };
  const getCurrentEchartInstance = (chart: any) => {
    return chart.getEchartsInstance();
  };
  const chartDom = useRef(null);
  let chartInstance: any = null;
  async function renderChart(chart: any) {
    const renderInstance = getCurrentEchartInstance(chart);
    if (renderInstance) {
      chartInstance = renderInstance;
    } else {
      chartInstance = echarts.init(chart);
    }
    await chartInstance.setOption({
      baseOption: {
        title: {
          show: false,
        },
        yAxis: {},
        series: [
          {
            name: "Total Automated",
            data: graphData.totalAutomated,
          },
          {
            name: "Total Pending",
            data: graphData.totalPending,
          },
          {
            name: "Total Current Completed",
            data: graphData.totalCurrentCompleted,
          },
        ],
      },
      // Media Queries
      media: [
        {
          option: {
            legend: {
              emphasis: {
                selectorLabel: {
                  show: false,
                },
              },
            },
            series: [
              {
                name: "Total Automated",
                emphasis: {
                  disabled: false,
                  focus: "series",
                },
                legendHoverLink: true,
              },
              {
                name: "Total Pending",
                emphasis: {
                  disabled: false,
                  focus: "series",
                },
                legendHoverLink: true,
              },
              {
                name: "Total Current Completed",
                emphasis: {
                  disabled: false,
                  focus: "series",
                },
                legendHoverLink: false,
              },
            ],
          },
        },
        {
          query: {
            maxWidth: 767,
          },
          option: {
            legend: {
              emphasis: {
                selectorLabel: {
                  show: false,
                },
              },
            },
            series: [
              {
                name: "Total Automated",
                emphasis: {
                  disabled: true,
                  focus: "none",
                },
                legendHoverLink: false,
              },
              {
                name: "Total Pending",
                emphasis: {
                  disabled: true,
                  focus: "none",
                },
                legendHoverLink: false,
              },
              {
                name: "Total Current Completed",
                emphasis: {
                  disabled: true,
                  focus: "none",
                },
                legendHoverLink: false,
              },
            ],
          },
        },
      ],
    });
  }

  useEffect(() => {
    if (chartDom.current !== null) {
      const chart: any = chartDom.current;
      const renderInstance = getCurrentEchartInstance(chart);
      if (renderInstance) {
        chartInstance = renderInstance;
      } else {
        chartInstance = echarts.init(chart);
      }
    }
  }, []);

  const GetQaAutomationReportsApi = async () => {
    getQaAutomationReport({ projectName, startDate, endDate })
      .then(async (response: any) => {
        const { qaReports } = response.data;
        let xaxis: any[] = [];
        let yaxisTotalAutomated: any[] = [];
        let yaxisTotalPending: any[] = [];
        let yaxisTotalCurrentCompleted: any[] = [];
        qaReports.forEach((val: AutomationGraphValue) => {
          const newDates: any = new Date(`${val.week}`).toLocaleDateString(
            "en-US",
            {
              month: "short",
              day: "numeric",
            }
          );
          xaxis = [...xaxis, newDates];
          yaxisTotalAutomated = [...yaxisTotalAutomated, val.totalAutomated];
          yaxisTotalPending = [...yaxisTotalPending, val.totalPending];
          yaxisTotalCurrentCompleted = [
            ...yaxisTotalCurrentCompleted,
            val.totalCurrentCompleted,
          ];
        });
        setGraphData({
          ...graphData,
          xAxis: xaxis,
          totalAutomated: yaxisTotalAutomated,
          totalPending: yaxisTotalPending,
          totalCurrentCompleted: yaxisTotalCurrentCompleted,
        });
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occurred",
            message: error.message,
          })
        );
      });
  };

  // Use Effect to monitor and update the graph on change in dropdown
  // Use Effect to monitor and update the graph on change in dropdown
  useEffect(() => {
    // Axios request to hit endpoint
    setLoading(true);
    // GetSprintApi();
    GetQaAutomationReportsApi();
  }, [startDate, endDate, projectName, graphType]);

  useEffect(() => {
    // Update chart values
    if (
      graphData.totalAutomated.reduce((a, b) => a + b, 0) === 0 &&
      graphData.totalCurrentCompleted.reduce((a, b) => a + b, 0) === 0 &&
      graphData.totalPending.reduce((a, b) => a + b, 0) === 0
    ) {
      if (chartDom.current !== null) {
        const renderInstance = getCurrentEchartInstance(chartDom.current);
        renderInstance.setOption({
          title: {
            show: true,
            textStyle: {
              color: "grey",
              fontSize: 20,
            },
            text: "No data",
            left: "center",
            top: "center",
          },
        });
        setLoading(false);
      }
    } else {
      if (chartDom.current !== null) {
        const barChart = chartDom.current;
        renderChart(barChart);
      }
      setLoading(false);
    }
    document.querySelector<any>(".credential-bar")?.setAttribute("role", "img");
  }, [graphData]);

  useEffect(() => {
    return () => {
      if (chartDom.current !== null) {
        chartInstance = getCurrentEchartInstance(chartDom.current);
      }
    };
  }, []);
  // Loading elements
  const loadingOption = {
    text: "loading",
    color: "#c23531",
    textColor: "#000",
    maskColor: "rgba(255, 255, 255, 0.8)",
    zlevel: 0,
    fontSize: 12,
    showSpinner: true,
    spinnerRadius: 10,
    lineWidth: 5,
    fontWeight: "normal",
    fontStyle: "normal",
  };
  useEffect(() => {
    // Update chart
    if (chartDom.current !== null) {
      const chart = getCurrentEchartInstance(chartDom.current);
    }
  }, [loading]);

  // Register the required components
  echarts.use([
    CanvasRenderer,
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    BarChart,
    LegendComponent,
    UniversalTransition,
    AriaComponent,
  ]);
  return (
    <BarGraphWrapper className="echart-wrapper">
      {/* BAR CHART */}
      <ReactEChartsCore
        echarts={echarts}
        ref={chartDom}
        option={getOption()}
        loadingOption={loadingOption}
        className="echart-wrapper credential-bar pl-md-2"
        lazyUpdate
      />
    </BarGraphWrapper>
  );
};

export default BarAutomationGraph;
