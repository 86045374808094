import React from "react";
import HealthCriteriaStyle from "./styled";

export type Color = "red" | "blue" | "orange" | "cyan";
export type Heading = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export interface Props {
  color: Color;
  headingType: Heading;
  headingText: any;
  textContent: any;
  progress: any;
}
const HealthCriteria = ({
  color = "red",
  headingType: Element = "h2",
  textContent,
  progress,
  headingText,
}: Props) => {
  return (
    <HealthCriteriaStyle>
      <div className="health">
        <Element>{headingText}</Element>
        <p>{textContent}</p>
        <div
          className={`workProgress ${color}`}
          style={{ width: `${progress}%` }}
        >
          <span>{progress}%</span>
        </div>
      </div>
    </HealthCriteriaStyle>
  );
};

export default HealthCriteria;
