import axios from "axios";
import CONFIG from "../../config";
import AddUserApi from "./AddUserApi";
import CONSTANTS from "../../common/constants";

const UpdateProjectsApi = async (projectForm: any, id: any) => {
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = "1";
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
      "Content-Type": "application/json",
    },
  };

  if (!projectForm.startDate) {
    projectForm.startDate = null;
  }

  if (!projectForm.endDate) {
    projectForm.endDate = null;
  }

  const formData = {
    name: projectForm.name,
    description: projectForm.description,
    scope: projectForm.scope,
    budget: projectForm.budget,
    projectManager: projectForm.manager,
    teamSize: Number(projectForm.teamSize),
    startAt: projectForm.startDate,
    endAt: projectForm.endDate,
    status: projectForm.status,
    active: projectForm.active,
    userId: projectForm.userId,
    onholdComment: projectForm.onhold_comment,
  };

  if (localStorage.getItem("role") !== CONSTANTS.ROLES.USER) {
    const userForm = {
      email: projectForm.userId,
      password: projectForm.password,
    };
    await AddUserApi(userForm);
  }

  await axios
    .post(
      `${
        CONFIG[
          process.env.REACT_APP_MY_ENV === undefined
            ? "dev"
            : process.env.REACT_APP_MY_ENV
        ].apiEndPoints.phrt
      }/v1/projects/${id}`,
      JSON.stringify(formData),
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export default UpdateProjectsApi;
