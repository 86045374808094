/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line import/prefer-default-export
import MyAccounts from "components/MyAccount/MyAccount";
import SearchBars from "components/SearchBar/SearchBar";
import SearchBarr from "components/SearchBar/styled";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AsideWrapper, TopNavWrapper, TopNavWrapperAccount } from "./styled";

export const AsideBar = () => {
  const [dashboardOpen, setdashboardOpe] = useState(false);
  const [reportOpen, setreportOpe] = useState(false);
  const [managedataOpen, setmanagedataOpe] = useState(false);
  const [ManageQAOpen, setManageQAOpen] = useState(false);
  return (
    <AsideWrapper className="aui-sidenav">
      <nav className="navbar-expand-lg">
        <div className="firstDiv">
          <img
            src={`${process.env.PUBLIC_URL}/images/PHRTAside.png`}
            alt="Project Health Report Tool Logo"
            style={{ width: "200px", height: "71px" }}
            className="py-1 my-2"
          />
        </div>
        <div className="aside-title">
          <div className="position-hamburger">
            <button
              className="navbar-toggler float-right m-3"
              type="button"
              data-toggle="collapse"
              data-target="#sideNavbar"
              aria-controls="sideNavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="aha-icon-hamburger-round" />
            </button>
            <div className="collapse navbar-collapse" id="sideNavbar">
              <ul className="w-100 p-1">
                <li>
                  <button
                    type="button"
                    className="btn btn-text aui-dropdown dropdown-toggle px-0"
                    onClick={() => setdashboardOpe(!dashboardOpen)}
                  >
                    Dashboard
                  </button>
                  {dashboardOpen && (
                    <ul className="p-0">
                      <li>
                        <NavLink
                          to="/dashboard"
                          className={(isActive) =>
                            `nav-link${!isActive ? " unselected" : ""}`
                          }
                        >
                          Projects
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/dashboard-qa"
                          className={(isActive) =>
                            `nav-link${!isActive ? " unselected" : ""}`
                          }
                        >
                          QA
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>

                <li>
                  <button
                    type="button"
                    className="btn btn-text aui-dropdown dropdown-toggle px-0"
                    onClick={() => setreportOpe(!reportOpen)}
                  >
                    Report
                  </button>
                  {reportOpen && (
                    <ul className="p-0">
                      <li>
                        <NavLink
                          to="/projectlist"
                          className={(isActive) =>
                            `nav-link${!isActive ? " unselected" : ""}`
                          }
                        >
                          Projects
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/report/qa"
                          className={(isActive) =>
                            `nav-link${!isActive ? " unselected" : ""}`
                          }
                        >
                          QA
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <button
                    type="button"
                    className="btn btn-text aui-dropdown dropdown-toggle px-0"
                    onClick={() => setmanagedataOpe(!managedataOpen)}
                  >
                    Manage Data
                  </button>
                  {managedataOpen && (
                    <ul className="p-0">
                      <li>
                        <NavLink
                          to="/reportslist"
                          className={(isActive) =>
                            `nav-link${!isActive ? " unselected" : ""}`
                          }
                        >
                          Projects
                        </NavLink>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="btn btn-text aui-dropdown dropdown-toggle px-0 qa"
                          onClick={() => setManageQAOpen(!ManageQAOpen)}
                        >
                          QA
                        </button>
                        {ManageQAOpen && (
                          <ul className="p-0">
                            <li>
                              <NavLink
                                to="/manage-data-qa"
                                className={(isActive) =>
                                  `nav-link${!isActive ? " unselected" : ""}`
                                }
                              >
                                QA Automation
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/qa-sprint-defects"
                                className={(isActive) =>
                                  `nav-link${!isActive ? " unselected" : ""}`
                                }
                              >
                                QA Sprint Defects
                              </NavLink>
                            </li>
                          </ul>
                        )}
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <TopNavWrapperAccount id="MyAccountNav">
                    <MyAccounts />
                  </TopNavWrapperAccount>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </AsideWrapper>
  );
};
export default AsideBar;
