import styled from "styled-components";

const AddDataWrapper = styled.div`
  min-height: 100vh;

  #maincontent {
    & .row {
      margin-right: 0px;
    }
    @media (min-width: 992px) {
      height: 100%;
      flex-grow: 1;
    }
    & .content-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (min-width: 992px) {
        flex-direction: row;
      }
      & .left-container {
        padding: 10px;
        @media (min-width: 550px) {
          padding: 15px;
        }
        @media (min-width: 991px) {
          padding: 19px 10px 19px 21px;
        }
        & main {
          @media (min-width: 360px) {
            display: flex;
            flex-direction: column;
            & .titleDiv {
              margin-bottom: 20px;
              padding-bottom: 20px;
              border-bottom: 0.6000000238418579px solid #bcc3ca;
              font: normal normal 600 24px/29px Montserrat;
            }
          }
          & .titleDiv {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 0.6000000238418579px solid #bcc3ca;
            font-size: 28px !important;
            font-weight: 500;
          }
          & .add-data {
            display: flex;
            & .btn {
              align-self: flex-end;
            }
          }
        }
      }
    }
    & .health {
      margin-top: 15px;
      margin-left: 20px;
      margin-right: 20px;
      font: normal normal 600 12px/18px Montserrat;
      .parDiv {
        width: 50%;
        background: #e7e7e7;
        position: relative;
        border-radius: 10px;
      }
    }
    & .project-name {
      color: #c10e21;
      text-decoration: underline;
    }
    & .test-cases {
      display: flex;
    }
    & .total-count {
      font: normal normal 600 12px/18px Montserrat;
      width: 30px;
      display: flex;
      justify-content: center;
      margin: 5px;
    }
    & .automated {
      padding: 0px 3px;
      margin: 5px;
      background-color: rgba(46, 129, 4, 0.1);
      color: #2e8104;
      font: normal normal 600 12px/18px Montserrat;
      width: 30px;
      display: flex;
      justify-content: center;
    }
    & .pending {
      width: 30px;
      margin: 5px;
      padding: 0px 3px;
      background-color: rgba(0, 129, 178, 0.1);
      color: #0081b2;
      font: normal normal 600 12px/18px Montserrat;
      display: flex;
      justify-content: center;
    }
    & .workProgress {
      height: 5px !important;
      border-radius: 10px;
      padding-right: 40px;
      font: normal normal 600 12px/18px Montserrat;
      span {
        position: absolute;
        top: -7px;
        right: -40px;
        font-size: 12px;
      }
      &.red {
        background: #c10e21;
        color: #c10e21;
      }
      &.green {
        background: #2e8104;
        color: #2e8104;
      }
    }
    .tableh {
      font-size: 14px !important;
      font-weight: 600;
    }
    .title {
      font: normal normal 600 24px/29px Montserrat;
    }
  }
`;
export default AddDataWrapper;
