import React, { useEffect, useState } from "react";
import AsideBar from "components/AsideBar";
import ScrollToTop from "components/scrollToTop";
import Header from "components/Header";
import Pagination, { IPagination } from "components/Pagination";
import { useHistory, useLocation } from "react-router-dom";
import { getQaAutomationReportListing } from "services/api/GetQaAutomationReportsApi";
import AddDataWrapper from "./styled";

const ManageDataQA = () => {
  const location = useLocation();
  const [automationArray, setAutomationArray] = useState([]);
  const [pageNumber, setPageNumber] = useState<number>(
    location?.state?.state.backToPage ? location?.state?.state.backToPage : 1
  );
  const [totalCount, setTotalCount] = useState();
  const history = useHistory();
  const handleClick = () => {
    history.push("/AddData");
  };
  const paginationSetLimit = 5;
  const defaultPagination: IPagination = {
    pageNumber: 1,
    pageSize: 6,
    totalCount: 6,
    totalPages: 5,
    isFirst: true,
    isLast: false,
    setPageNumber,
    paginationSetLimit,
  };
  const [pagination, setPagination] = useState<IPagination>(defaultPagination);

  const qaAutomatedListRequest = async () => {
    await getQaAutomationReportListing("").then((automationdata: any) => {
      setAutomationArray(automationdata?.data.qaReports);
      // eslint-disable-next-line no-underscore-dangle
      setPagination({ ...automationdata?._pagination, setPageNumber });
      // eslint-disable-next-line no-underscore-dangle
      setTotalCount(automationdata?._pagination.totalCount);
    });
  };
  useEffect(() => {
    qaAutomatedListRequest();
  }, []);
  const page = {
    defaultPagination,
    pagination,
    pageNumber,
    totalCount,
  };
  const WeekOfMonth = (date: any) => {
    const currentDate = new Date(date);
    const day = currentDate.getDate();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const offset = firstDayOfMonth.getDay();
    const weekOfMonth = Math.ceil((day + offset) / 7);
    return weekOfMonth;
  };
  const getDate = (date: any) => {
    const dateObject = new Date(date);
    const formattedDate = dateObject.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate;
  };
  const calculateProgress = (automated: any, total: any) => {
    const progress = (automated / total) * 100;
    return parseFloat(progress.toFixed(0));
  };
  return (
    <AddDataWrapper className="container-fluid d-flex flex-column w-100 p-0">
      <main className="d-flex" id="maincontent">
        <div className="content-container">
          <AsideBar />
          <div className="left-container flex-grow-1">
            <Header />
            <ScrollToTop />
            <div className="row my-4">
              <div className="col-9 titleDiv">
                <h3>Automation Data</h3>
              </div>
              <div className="col-3 add-data text-right">
                <button
                  type="button"
                  className="btn btn-round btn-primary"
                  onClick={handleClick}
                >
                  Add Data
                </button>
              </div>
            </div>
            <div className="table-data">
              <table className="aui-responsive-status-table">
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="aui-th tableh">Project</div>
                    </th>
                    <th scope="col">
                      <div className="aui-th tableh">Date</div>
                    </th>
                    <th scope="col">
                      <div className="aui-th tableh">
                        Test Cases <br /> &#40;Total/ Automated/ Pending&#41;
                      </div>
                    </th>
                    <th scope="col">
                      <div className="aui-th tableh">Created on</div>
                    </th>
                    <th scope="col">
                      <div className="aui-th tableh">Health</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {automationArray.map((automation: any) => (
                    <tr
                      className={
                        calculateProgress(
                          automation.totalAutomated,
                          automation.totalCount
                        ) >= 70
                          ? "aui-table-status-success"
                          : "aui-table-status-danger"
                      }
                    >
                      <td data-title="Data">
                        <div className="aui-td">
                          <a className="project-name" href="/report/qa">
                            {automation.projectCode}
                          </a>
                        </div>
                      </td>
                      <td data-title="Week">
                        <div className="aui-td">{getDate(automation.week)}</div>
                      </td>
                      <td data-title="Test Cases">
                        <div className="aui-td test-case">
                          <div className="total-count">
                            {automation.totalCount}{" "}
                          </div>
                          <div className="automated">
                            {automation.totalAutomated}{" "}
                          </div>
                          <div className="pending">
                            {automation.totalPending}
                          </div>
                        </div>
                      </td>
                      <td data-title="Created on">
                        <div className="aui-td">
                          {getDate(automation.createdAt)}
                        </div>
                      </td>
                      <td data-title="Health" className="health ">
                        <div className="aui-td">
                          <div className="parDiv">
                            <div
                              className={
                                calculateProgress(
                                  automation.totalAutomated,
                                  automation.totalCount
                                ) >= 70
                                  ? "workProgress  green"
                                  : "workProgress  red"
                              }
                              style={{
                                width: `${calculateProgress(
                                  automation.totalAutomated,
                                  automation.totalCount
                                )}%`,
                              }}
                            >
                              <span>
                                {calculateProgress(
                                  automation.totalAutomated,
                                  automation.totalCount
                                )}
                                %
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="w-100">
              {pagination && Number(pagination.totalPages) > 0 ? (
                <Pagination
                  pageNumber={pagination.pageNumber}
                  pageSize={pagination.pageSize}
                  totalCount={pagination.totalCount}
                  totalPages={pagination.totalPages}
                  isFirst={pagination.isFirst}
                  isLast={pagination.isLast}
                  setPageNumber={pagination.setPageNumber}
                  paginationSetLimit={5}
                />
              ) : null}
            </div>
          </div>
        </div>
      </main>
    </AddDataWrapper>
  );
};
export default ManageDataQA;
