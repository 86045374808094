import React, { useEffect } from "react";
import HealthCriteria from "components/healthCriteria/healthCriteria";
import { getQaAutomationStatusApi } from "services/api/GetQaAutomationReportsApi";
import { showToast } from "components/Toast/toast.slice";
import store from "app/store";
import CardsWrapperQA from "./styled";

const DashBoardQA = () => {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [totalTestcasesProgress, setTotalTestcasesProgress] = React.useState(0);
  const GetQaAutomationReportsApi = async () => {
    getQaAutomationStatusApi({ startDate, endDate })
      .then(async (response: any) => {
        const { dashboardReports } = response.data;
        let totalTestcases = 0;
        let totalAutomatedTestcases = 0;
        dashboardReports.forEach((val: any) => {
          totalTestcases += val.totalCount;
          totalAutomatedTestcases += val.totalAutomated;
        });
        setTotalTestcasesProgress(
          Math.round((totalAutomatedTestcases / totalTestcases) * 100)
        );
      })
      .catch((error: any) => {
        store.dispatch(
          showToast({
            type: "danger",
            title: "Error Occurred",
            message: error.message,
          })
        );
      });
  };

  useEffect(() => {
    GetQaAutomationReportsApi();
  }, []);
  return (
    <CardsWrapperQA>
      <div
        className="first-container d-flex flex-row flex-wrap"
        aria-label="Overview of Projects"
      >
        <div className="col-md-3 pl-0">
          <HealthCriteria
            color="red"
            headingText="Automation"
            headingType="h6"
            textContent=""
            progress={totalTestcasesProgress || 0}
          />
        </div>
        <div className="col-md-3 pl-0">
          <HealthCriteria
            color="orange"
            headingText="Sprint Defects"
            headingType="h6"
            textContent=""
            progress={40}
          />
        </div>
        {/* <div className="col-md-3 pl-0">
          <HealthCriteria
            color="blue"
            headingText="UAT Status"
            headingType="h6"
            textContent=""
            progress={48}
          />
        </div>
        <div className="col-md-3 pl-0">
          <HealthCriteria
            color="cyan"
            headingText="Prod Issues"
            headingType="h6"
            textContent=""
            progress={72}
          />
        </div> */}
      </div>
    </CardsWrapperQA>
  );
};

export default DashBoardQA;
