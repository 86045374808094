import React, { useEffect, useState } from "react";
import AsideBar from "components/AsideBar";
import ScrollToTop from "components/scrollToTop";
import Header from "components/Header";
import DashBoardQA from "components/DashboardComponents/DashBoardQA";
import DropDown from "components/DropDown/index";
import { format } from "date-fns";
import { getQaAutomationReport } from "services/api/GetQaAutomationReportsApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../../components/AdminFooter/index";
import DashboardQAstyle from "./styled";
import BarAutomationStatusGraph from "./HBAutomationStatusChart";
import AutomationForecast from "./AutomationForecast";

const DashboardQA = () => {
  const [projectName, setProjectName] = React.useState("ShopCPR");
  const today = new Date();
  const startDateFormatted = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 28
  )
    .toISOString()
    .split("T")[0];
  const endDateFormatted = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split("T")[0];
  const [startDate, setStartDate] = React.useState(startDateFormatted);
  const [endDate, setEndDate] = React.useState(endDateFormatted);
  const [startDateValue, setStartDateValue] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 28)
  );
  const [endDateValue, setEndDateValue] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );
  const [selected, setSelected] = React.useState("");
  const handleDateChange = (dates: [any, any]) => {
    const [start, end] = dates;
    if (start) {
      const formattedStartDate = format(start, "yyyy-MM-dd");
      setStartDate(formattedStartDate);
      setStartDateValue(start);
    }
    if (end) {
      const formattedEndDate = format(end, "yyyy-MM-dd");
      setEndDate(formattedEndDate);
      setEndDateValue(end);
    }
  };

  const [options, setOptions] = useState([
    "ShopCPR",
    "Intelligo Store",
    "QCT",
    "ADH",
    "CP",
    "CE",
    "eBooks",
    "Intelligo",
    "Intelligo premier",
    "Atlas React",
    "Elearning",
  ]);

  return (
    <DashboardQAstyle className="container-fluid d-flex flex-column w-100 p-0">
      <main className="d-flex" id="maincontent">
        <div className="content-container">
          <AsideBar />
          <div className="left-container flex-grow-1">
            <Header />
            <ScrollToTop />
            <div className="titleDiv">
              <h3>QA Dashboard</h3>
            </div>
            <DashBoardQA />
            <div className="T-Title d-flex justify-content-between w-100 pr-4">
              <div className="T-Titles">HB Automation Status</div>
              {/* <DropDown dropdownText="Jan Week 1 - Mar Week 3">
                  <ul className="">
                    <li>
                      <button type="button" className="btn btn-text ">
                        Jan Week 1-Mar Week 3
                      </button>
                    </li>
                    <li>
                      <button type="button" className="btn btn-text ">
                        Jan Week 1-Mar Week 3
                      </button>
                    </li>
                    <li>
                      <button type="button" className="btn btn-text ">
                        Jan Week 1-Mar Week 3
                      </button>
                    </li>
                    <li>
                      <button type="button" className="btn btn-text ">
                        Jan Week 1-Mar Week 3
                      </button>
                    </li>
                    <li>
                      <button type="button" className="btn btn-text">
                        Jan Week 1-Mar Week 3
                      </button>
                    </li>
                  </ul>
                </DropDown> */}
              <div className="date-picker-wrapper">
                <DatePicker
                  dateFormat="dd-MM-yyyy"
                  className="date-picker"
                  selected={startDateValue}
                  onChange={handleDateChange}
                  startDate={startDateValue}
                  endDate={endDateValue}
                  selectsRange
                  placeholderText="Select Date Range"
                  required
                />
              </div>
            </div>
            <BarAutomationStatusGraph
              projectName={projectName}
              startDate={startDate}
              endDate={endDate}
            />
            <div className="d-flex flex-row flex-nowrap justify-content-between mb-4">
              <div>
                <span className="T-Titles">
                  Automation Forecast: {projectName}
                </span>
              </div>
            </div>
            <div className="d-flex flex-row flex-nowrap">
              <div className="d-flex flex-column flex-grow-1">
                <div className="w-75">
                  <AutomationForecast
                    projectName={projectName}
                    startDate={startDate}
                    endDate={endDate}
                    predictionType="SMA"
                  />
                </div>
              </div>
              <div className="d-flex flex-column scrollable-project-list">
                {options.map((option: any) => {
                  return (
                    <button
                      type="button"
                      className={`btn btn-text text-left py-3 p-4 ${
                        selected === option ? "text-danger" : ""
                      } `}
                      onClick={() => {
                        setProjectName(option);
                        setSelected(option);
                      }}
                    >
                      {option}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </DashboardQAstyle>
  );
};

export default DashboardQA;
