import React, { useEffect, useState } from "react";
import AsideBar from "components/AsideBar";
import ScrollToTop from "components/scrollToTop";
import Header from "components/Header";
import { useHistory } from "react-router-dom";
import getQaSprintDefectsApi from "services/api/getQaSprintDefectsApi";
import AddSprintDefectDataWrapper from "./styled";

const QaSprintDefects = () => {
  const history = useHistory();
  const [defectsArray, setDefectsArray] = useState({
    "AHA Data Hub": {
      total: 50,
      open: 0,
      closed: 44,
      inProgress: 0,
      health: 88,
    },
    "AHA EBooks": {
      total: 50,
      open: 15,
      closed: 31,
      inProgress: 2,
      health: 62,
    },
    "AHA Elearning": {
      total: 50,
      open: 0,
      closed: 43,
      inProgress: 1,
      health: 86,
    },
    Atlas: {
      total: 50,
      open: 22,
      closed: 22,
      inProgress: 0,
      health: 44,
    },
    "CE/CME Service": {
      total: 50,
      open: 1,
      closed: 48,
      inProgress: 1,
      health: 96,
    },
    CarePlans: {
      total: 0,
      open: 0,
      closed: 0,
      inProgress: 0,
      health: -9223372036854775808,
    },
    Intelligo: {
      total: 50,
      open: 0,
      closed: 44,
      inProgress: 1,
      health: 88,
    },
    QCT: {
      total: 50,
      open: 7,
      closed: 30,
      inProgress: 4,
      health: 60,
    },
    "Shop CPR": {
      total: 50,
      open: 3,
      closed: 35,
      inProgress: 6,
      health: 70,
    },
  });
  const handleClick = () => {
    history.push("/AddData");
  };
  // const qaSprintDefectsRequest = async () => {
  //   await getQaSprintDefectsApi().then((sprintdata: any) => {
  //     console.log(sprintdata, "auto");
  //     setDefectsArray(sprintdata?.data.qaReports);
  //     // eslint-disable-next-line no-underscore-dangle
  //     setPagination({ ...sprintdata?._pagination, setPageNumber });
  //     // eslint-disable-next-line no-underscore-dangle
  //     setTotalCount(sprintdata?._pagination.totalCount);
  //   });
  // };
  // useEffect(() => {
  //   qaSprintDefectsRequest();
  // }, []);
  return (
    <AddSprintDefectDataWrapper className="container-fluid d-flex flex-column w-100 p-0">
      <main className="d-flex" id="maincontent">
        <div className="content-container">
          <AsideBar />
          <div className="left-container flex-grow-1">
            <Header />
            <ScrollToTop />
            <div className="row my-4">
              <div className="col-9 titleDiv">
                <h3>QA Sprint Defects</h3>
              </div>
              <div className="col-3 add-data text-right">
                <button
                  type="button"
                  className="btn btn-round btn-primary"
                  onClick={handleClick}
                >
                  Add Data
                </button>
              </div>
            </div>
            <div className="table-data">
              <table className="aui-responsive-status-table">
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="aui-th tableh">Project</div>
                    </th>
                    <th scope="col">
                      <div className="aui-th tableh">
                        Defects Status
                        <br /> &#40;Total / Open / In-Progress / Closed&#41;
                      </div>
                    </th>
                    <th scope="col">
                      <div className="aui-th tableh">Health</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(defectsArray).map(([key, value]) => (
                    <tr
                      className={
                        value.health >= 70
                          ? "aui-table-status-success"
                          : "aui-table-status-danger"
                      }
                    >
                      <td data-title="Project">
                        <div className="aui-td">
                          <a className="project-name" href="/report/qa">
                            {key}
                          </a>
                        </div>
                      </td>
                      <td data-title="Test Cases">
                        <div className="aui-td test-case">
                          <div className="total-count">{value.total}</div>
                          <div className="open">{value.open}</div>
                          <div className="inProgress">{value.inProgress}</div>

                          <div className="closed">{value.closed}</div>
                        </div>
                      </td>
                      <td data-title="Health" className="health ">
                        <div className="aui-td">
                          <div className="parDiv">
                            <div
                              className={
                                value.health >= 70
                                  ? "workProgress  green"
                                  : "workProgress  red"
                              }
                              style={{
                                width: `${value.health}%`,
                              }}
                            >
                              <span>{value.health}%</span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </AddSprintDefectDataWrapper>
  );
};
export default QaSprintDefects;
