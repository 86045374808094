import styled from "styled-components";

const FilterStyle = styled.div`
  .filter-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter-icon-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  }
  .filter-data-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .graph-btn{
    border: none;
    background:none;
    background-color: transparent;
    width:60px;
    height:41px;
  }

  }
  #dropdown {
    border: none;
    outline: none;
    background: none;
  }
  #dropdown:hover {
    cursor: pointer;
  }
  .date-picker {
    padding:3px;
    background:none;
    border:none;
  }
  .date-picker:hover {
    cursor: pointer;
  }
  .reports{
    padding-left:4px;
    font-size:12px;
    color:#777979
  }
  .projects{
    padding-left:4px;
    font-size:12px;
    color:#777979
  }
`;
export default FilterStyle;
