import axios from "axios";
import CONFIG from "../../config";

export const getQaAutomationReport = async (props: any): Promise<any> => {
  const { startDate, endDate } = props;
  const today = new Date();
  const defstartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const defendDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 28
  );

  const defaultendDate = defstartDate.toISOString().split("T")[0];
  const defaultstartDate = defendDate.toISOString().split("T")[0];

  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = 1;
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(
      `${
        CONFIG[
          process.env.REACT_APP_MY_ENV === undefined
            ? "dev"
            : process.env.REACT_APP_MY_ENV
        ].apiEndPoints.dashboard
      }/v1/qaReports?project=${props.projectName || ""}&startDate=${
        props.startDate || defaultstartDate
      }&endDate=${props.endDate || defaultendDate}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
export const getQaAutomationReportListing = async (
  props: any
): Promise<any> => {
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = 1;
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(
      `${
        CONFIG[
          process.env.REACT_APP_MY_ENV === undefined
            ? "dev"
            : process.env.REACT_APP_MY_ENV
        ].apiEndPoints.dashboard
      }/v1/qaReports`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
export const getJiraAutomationReport = async (props: any): Promise<any> => {
  const { projectName, startDate, endDate } = props;
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = 1;
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
    },
  };
  return axios
    .get(
      `${
        CONFIG[
          process.env.REACT_APP_MY_ENV === undefined
            ? "dev"
            : process.env.REACT_APP_MY_ENV
        ].apiEndPoints.dashboard
      }/v1/jiraAutomationReports?projectName=${projectName}&startDate=${startDate}&endDate=${endDate}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getQaAutomationStatusApi = async (props: any): Promise<any> => {
  const { startDate, endDate } = props;
  const today = new Date();
  const defstartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const defendDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 28
  );

  const defaultendDate = defstartDate.toISOString().split("T")[0];
  const defaultstartDate = defendDate.toISOString().split("T")[0];

  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = 1;
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
    },
  };
  return axios
    .get(
      `${
        CONFIG[
          process.env.REACT_APP_MY_ENV === undefined
            ? "dev"
            : process.env.REACT_APP_MY_ENV
        ].apiEndPoints.dashboard
      }/v1/dashboardReports?startDate=${
        startDate || defaultstartDate
      }&endDate=${endDate || defaultendDate}`,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
