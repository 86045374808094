import React from "react";

import { LineGraphWrapper } from "./styled";
import LineAutomationGraph from "./LineAutomationGraph.tsx";
import LineForcastGraph from "./LineForecastGraph.tsx";
import LineSprintGraph from "./LineSprintGraph.tsx";

const AutomationForecastGraph: any = (props: any) => {
  const {
    reportsType,
    projectName,
    startDateRange,
    endDateRange,
    forecast,
    graphType,
  } = props;

  return (
    <LineGraphWrapper className="echart-wrapper pl-lg-0 ">
      <div className="row mt-4 m-sm-0 mt-sm-4 align-items-center">
        <div className="col-sm-5 chart-dropdown">{/* Add Filter here */}</div>
      </div>

      {reportsType === "Sprint" && (
        <LineSprintGraph
          projectName={projectName}
          startDate={startDateRange}
          endDate={endDateRange}
          forecast={forecast}
          graphType={graphType}
        />
      )}

      {reportsType === "Automation" && forecast === false && (
        <LineAutomationGraph
          projectName={projectName}
          startDate={startDateRange}
          endDate={endDateRange}
          graphType={graphType}
        />
      )}

      {reportsType === "Automation" && forecast === true && (
        <LineForcastGraph
          projectName={projectName}
          startDate={startDateRange}
          endDate={endDateRange}
          graphType={graphType}
        />
      )}

      {/* Sprint Defect Values */}

      {/* <ReactEChartsCore
        echarts={echarts}
        ref={chartDom3}
        option={setOptionsSprintDefect()}
        className="echart-wrapper transaction-log px-3 mx-2"
        loadingOption={loadingOption}
        showLoading={loading}
        opts={{ renderer: "svg" }}
        lazyUpdate
      /> */}
    </LineGraphWrapper>
  );
};

export default AutomationForecastGraph;
