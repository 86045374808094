/* eslint-disable no-console */
import axios from "axios";
import CONFIG from "../../config";

const PiActive = async (projectId: any) => {
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = 1;
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
    },
  };
  return axios
    .get(
      `${
        CONFIG[
          process.env.REACT_APP_MY_ENV === undefined
            ? "dev"
            : process.env.REACT_APP_MY_ENV
        ].apiEndPoints.phrt
      }/v1/pi?projectId=${projectId}&piStatus=${1}`,
      config
    )
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export default PiActive;
