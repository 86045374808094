import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import AddqaReportsApi from "services/api/AddqaReports";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useHistory } from "react-router-dom";
import { showToast } from "components/Toast/toast.slice";
import { UploadProjects } from "./styled";

function UploadProject() {
  const [uploadedFile, setUploadedFile] = useState([]);
  const [values, setValues] = useState<any>([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const routeChange = () => {
    const path = `/manage-data-qa`;
    history.push(path);
  };
  const validateUploadData = (data: any, file: any) => {
    let validate = true;
    const headers = Object.keys(data[0]);
    const requiredHeaders = [
      "project",
      "week",
      "total_count",
      "total_pending",
      "total_current_completed",
      "total_automated_tcs",
    ];
    const missingHeaders = requiredHeaders.filter(
      (header) => !headers.includes(header)
    );
    if (missingHeaders.length > 0) {
      const toast = {
        visibility: true,
        message: `Missing headers: ${missingHeaders.join(", ")}`,
        title: "Failure",
        type: "danger",
      };
      dispatch(showToast(toast));
      validate = false;
    }
    const hasNullValue = data.some((obj: any) =>
      Object.values(obj).includes("")
    );
    if (hasNullValue) {
      const toast = {
        visibility: true,
        message: `Data should not contain  null values`,
        title: "Failure",
        type: "danger",
      };
      dispatch(showToast(toast));
      validate = false;
    }
    if (validate) {
      setValues(data);
      setUploadedFile(file.name);
    }
  };

  const changeHandler = (event: any) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        validateUploadData(results.data.slice(0, -1), file);
      },
    });
  };
  const CancelReport = () => {
    setUploadedFile([]);
  };
  const UploadQAReport = async () => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    await AddqaReportsApi(values).then((data: any) => {
      const toast = {
        visibility: true,
        message: "Project Added Successfully",
        title: "Success",
        type: "success",
      };
      dispatch(showToast(toast));
      routeChange();
      dispatch(setPageLoadingStatus({ isPageLoading: false }));
    });
  };
  return (
    <UploadProjects>
      <div className="upload-container">
        <div className="aui-drag-section">
          <div className="aui-drag-outline">
            <div className="aui-drag-area d-flex align-items-center justify-content-center">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <i className="aha-icon-upload aui-drag-upload-icon" />
                <span
                  className="h7 font-600 mb-0"
                  aria-label="drag and drop files here"
                >
                  Drag it here / Upload File
                </span>
                <span className="my-3">or</span>
                <div className="aui-btn-files">
                  <input
                    id="uploadfile"
                    type="file"
                    name="uploadfile"
                    accept=".csv"
                    aria-label="Browse Files"
                    onChange={changeHandler}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label
                    htmlFor="uploadfile"
                    className="btn btn-round btn-primary m-0"
                    aria-hidden="true"
                  >
                    <span>Browse Files</span>
                  </label>
                </div>
                <br />
                <span>{uploadedFile}</span>
              </div>
            </div>
            <div className="button-group">
              <button
                type="button"
                className="btn btn-link"
                onClick={CancelReport}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={UploadQAReport}
              >
                Upload Data
              </button>
            </div>
          </div>
        </div>
      </div>
    </UploadProjects>
  );
}

export default UploadProject;
