import React from "react";
import { MountainGraphWrapper } from "./styled";
import MountainSprintGraph from "./MountainSprintGraph/index";
import MountainAutomationGraph from "./MountainAutomationGraph/index";

const MountainGraph: any = (props: any) => {
  const { reportsType, projectName, startDate, endDate, forecast, graphType } =
    props;
  return (
    <MountainGraphWrapper className="echart-wrapper pl-lg-0 ">
      {reportsType === "Sprint" && (
        <MountainSprintGraph
          projectName={projectName}
          startDate={startDate}
          endDate={endDate}
          forecast={forecast}
          graphType={graphType}
        />
      )}

      {reportsType === "Automation" && (
        <MountainAutomationGraph
          projectName={projectName}
          startDate={startDate}
          endDate={endDate}
          forecast={forecast}
          graphType={graphType}
        />
      )}
    </MountainGraphWrapper>
  );
};

export default MountainGraph;
