import React from "react";
import AsideBar from "components/AsideBar";
import Header from "components/Header";
import ScrollToTop from "components/scrollToTop";
import Footer from "components/Footer";
import Toast from "components/Toast";
import Loader from "components/Loader";
import UploadProject from "components/UploadProject";
import AddDataWrapper from "./styled";

const AddData = () => {
  return (
    <AddDataWrapper className="container-fluid d-flex flex-column w-100 p-0">
      <div className="d-flex" id="maincontent">
        <div className="d-flex content-container">
          <AsideBar />
          <div className="left-container flex-grow-1">
            <Header />
            <ScrollToTop />
            <main className="">
              <div className="titleDiv">
                <h3>Automation Data</h3>
              </div>
              <div className="navbar-expand-lg">
                <button
                  type="button"
                  className="aui-navbar-toggler navbar-toggler btn btn-round btn-secondary"
                  data-toggle="collapse"
                  data-target="#navbarList"
                  aria-controls="navbarList"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  Menu
                </button>
                <div className="aui-p-tabs">
                  <div id="navbarList" className="collapse navbar-collapse">
                    <ul className="nav nav-tabs" role="tablist">
                      {/* <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#ptab1"
                          role="tab"
                        >
                          Create New Project
                        </a>
                      </li> */}
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#ptab2"
                          role="tab"
                        >
                          Upload a Project
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content">
                    <div
                      id="ptab1"
                      className="tab-pane fade pt-4"
                      role="tabpanel"
                    >
                      <p className="mb-0">Content of Tab 2</p>
                    </div>
                    <div
                      id="ptab2"
                      className="tab-pane active pt-4"
                      role="tabpanel"
                    >
                      <p className="mb-0">
                        <UploadProject />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      <Footer />
      <Toast />
      <Loader />
    </AddDataWrapper>
  );
};
export default AddData;
