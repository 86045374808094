import React from "react";
import { Switch } from "react-router-dom";
import Dashboard from "pages/Dashboard/DashboardPage";
import ProjectList from "pages/Projects/ProjectPage";
import ProjectName from "pages/Projects/ProjectDetail/ProjectDetailPage";
import AddProject from "pages/Projects/AddProject/AddProjectPage";
import AddReport from "pages/Reports/AddReport/Addreport";
import AddData from "pages/AddData/AddDataPage";
import ReportList from "pages/Reports/ReportListPage/ReportListPage";
import ProjectManagement from "pages/Projects/ProjectManagement/ProjectManagementPage";
import HomePage from "pages/Loginpage/Loginpage";
import QAReportsPage from "pages/Reports/QAReportsPage/QAReportsPage";
import ManageDataQA from "pages/ManageDataQA/ManageData";
import QaSprintDefects from "pages/QaSprintDefects";
import DashboardQA from "../pages/DashboardQA/DashboardQA";
import Route from "./Route";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={HomePage as any} hideHeader />
      <Route
        path="/dashboard"
        exact
        isPrivate
        component={Dashboard as any}
        hideHeader
      />
      <Route path="/AddData" isPrivate component={AddData as any} hideHeader />
      <Route
        exact
        path="/projectlist"
        component={ProjectList as any}
        hideHeader
      />

      <Route path="/dashboard-qa" component={DashboardQA as any} hideHeader />
      <Route
        path="/projectlist/projectname"
        component={ProjectName as any}
        hideHeader
      />
      <Route
        exact
        path="/projectlist/projectmanagement"
        component={ProjectManagement as any}
        hideHeader
      />
      <Route
        path="/projectlist/addproject"
        component={AddProject as any}
        hideHeader
      />
      <Route
        path="/projectlist/addreport"
        component={AddReport as any}
        hideHeader
      />
      <Route path="/reportslist" component={ReportList as any} hideHeader />
      <Route
        path="/manage-data-qa"
        component={ManageDataQA as any}
        hideHeader
      />
      <Route
        path="/qa-sprint-defects"
        component={QaSprintDefects as any}
        hideHeader
      />
      <Route path="/report/qa" component={QAReportsPage as any} adminRoute />
    </Switch>
  );
}
