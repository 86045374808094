import styled from "styled-components";

const HealthCriteriaStyle = styled.div`
  border: 1px solid #cecece;
  padding: 1rem;
  border-radius: 2px;
  .health {
    p {
      font-size: 12px;
    }
    .workProgress {
      height: 6px;
      position: absolute;
      left: 0px;
      bottom: 0px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      span {
        position: absolute;
        font-size: 12px;
        right: 0px;
        top: -17px;
      }
      &.red {
        background: #c10f21;
        color: #c10f21;
      }
      &.blue {
        background: #0063ac;
        color: #0063ac;
      }
      &.orange {
        background: #ff8900;
        color: #ff8900;
      }
      &.cyan {
        background: #009d92;
        color: #009d92;
      }
    }
  }
`;
export default HealthCriteriaStyle;
