import React, { useState } from "react";
import ScrollToTop from "components/scrollToTop";
import Header from "components/Header";
import AsideBar from "components/AsideBar";
import Filter from "components/Filter";
import AutomationForecastGraph from "./Component/LineGraph";
import { QAReportsWrapper } from "./styled";
import BarGraph from "./Component/BarGraph";
import MountainGraph from "./Component/MountainGraph";

export interface FilterProps {
  reportsType: any;
  projectName: any;
  graphType: any;
  startDateRange: any;
  endDateRange: any;
  toggleForecast: any;
}

const QAReportsPage = () => {
  const today = new Date();
  const startDateFormatted = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 28
  )
    .toISOString()
    .split("T")[0];
  const endDateFormatted = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  )
    .toISOString()
    .split("T")[0];
  const [filter, setFilter] = useState<FilterProps>({
    reportsType: "Automation",
    projectName: "ADH",
    graphType: "Line",
    startDateRange: startDateFormatted,
    endDateRange: endDateFormatted,
    toggleForecast: false,
  });

  const updateFilter = (key: any, value: any) => {
    if (key === "startDateRange") {
      setFilter({
        ...filter,
        startDateRange: value,
      });
    }
    if (key === "endDateRange") {
      setFilter({
        ...filter,
        endDateRange: value,
      });
    }
    if (key === "projectName") {
      setFilter({
        ...filter,
        projectName: value,
      });
    }
    if (key === "reportsType") {
      setFilter({
        ...filter,
        reportsType: value,
      });
    }
    if (key === "graphType") {
      setFilter({
        ...filter,
        graphType: value,
      });
    }
  };
  return (
    <QAReportsWrapper className="container-fluid d-flex flex-column w-100 p-0">
      <main className="d-flex" id="maincontent">
        <div className="content-container">
          <AsideBar />
          <div className="left-container flex-grow-1">
            <Header />
            <ScrollToTop />
            <div className="d-flex justify-content-between">
              <h1 className="main-title">
                {filter.reportsType} {filter.projectName}
              </h1>
              <label
                className="forecast-checkbox mr-8"
                htmlFor="forecast-checkbox"
              >
                <input
                  type="checkbox"
                  name="forecast"
                  className="mx-2"
                  id="forecast-checkbox"
                  disabled={filter.reportsType === "Sprint"}
                  onClick={() => {
                    setFilter({
                      ...filter,
                      toggleForecast: !filter.toggleForecast,
                    });
                  }}
                />
                Forecast
              </label>
            </div>
            {/* Filters Component  */}
            <Filter filter={filter} updateFilter={updateFilter} />
            {/* Charts for reference */}
            <>
              {filter.graphType === "Line" && (
                <AutomationForecastGraph
                  graphType={filter.graphType}
                  reportsType={filter.reportsType}
                  projectName={filter.projectName}
                  startDateRange={filter.startDateRange}
                  endDateRange={filter.endDateRange}
                  forecast={filter.toggleForecast}
                />
              )}
              {filter.graphType === "Bar" && (
                <BarGraph
                  graphType={filter.graphType}
                  reportsType={filter.reportsType}
                  projectName={filter.projectName}
                  startDate={filter.startDateRange}
                  endDate={filter.endDateRange}
                  forecast={filter.toggleForecast}
                />
              )}
              {filter.graphType === "Mountain" && (
                <MountainGraph
                  graphType={filter.graphType}
                  reportsType={filter.reportsType}
                  projectName={filter.projectName}
                  startDate={filter.startDateRange}
                  endDate={filter.endDateRange}
                  forecast={filter.toggleForecast}
                />
              )}
            </>
          </div>
        </div>
      </main>
    </QAReportsWrapper>
  );
};

export default QAReportsPage;
