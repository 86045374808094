import axios from "axios";
import CONFIG from "../../config";

const AddqaReportsApi = async (reportForm: any) => {
  console.log(reportForm, "calling");
  const bearerToken = localStorage.getItem("user") as string;
  const tenantId = "1";
  const config = {
    headers: {
      Authorization: `Bearer ${JSON.parse(bearerToken)}`,
      "X-Tenant-Id": tenantId,
      "Content-Type": "application/json",
    },
  };
  const qaReport = reportForm.map((Form: any) => {
    return {
      projectCode: Form.project,
      week: Form.week,
      totalCount: parseInt(Form.total_count, 10),
      totalAutomated: parseInt(Form.total_automated_tcs, 10),
      totalPending: parseInt(Form.total_pending, 10),
      totalCurrentCompleted: parseInt(Form.total_current_completed, 10),
    };
  });
  await axios
    .post(
      `${
        CONFIG[process?.env?.REACT_APP_MY_ENV || "dev"].apiEndPoints.dashboard
      }/v1/qaReports`,
      JSON.stringify({ qaReport }),
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      console.log(error.response.status);
      console.log(error.response.data.error.details[0].message);
      console.log(error.message);
      throw error;
    });
};

export default AddqaReportsApi;
