import styled from "styled-components";

export const LineGraphWrapper = styled.div`
  .font-change {
    color: "#222328";
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    font-family: Montserrat;
    @media (min-width: 320px) and (max-width: 374px) {
      font-size: 16px;
    }
  }
`;

export const LineGraphHeader = styled.div``;
